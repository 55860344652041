<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="content-header row">
                <div class="col-12 mb-1  mr-4 rounded ">
                    <h3 class=" mb-0 py-2 pl-2 bg-white">Appointments Settings</h3>

                </div>
            </div>
            <div class="row ">
                <div class="col-12 ">
                    <ul class="nav nav-tabs p-0 m-0 bg-white py-1 pl-1">
                        <li @click="currentState = JSON.parse(JSON.stringify(appointmentSetting))" v-for="(appointmentSetting,index) in appointmentSettings" :key="index" class="nav-item ">
                            <a class="nav-link cursor-pointer" :class="[appointmentSetting.key == currentState.key ? 'active' : '']">
                                <i class='bx bxs-brightness align-middle'></i>
                                <span class="align-middle text-capitalize">{{(appointmentSetting.key.replaceAll("_", " ").replace("appointment", ""))}}</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="row ">

                <div class="col-lg-12">
                    <div v-if="currentState.key === 'appointment_reference_prefix'" class="card-body bg-white rounded">

                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentReferencePrefix" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_reference_suffix'" class="card-body bg-white rounded">

                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentReferenceSuffix" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_online_appointment_status'" class="card-body bg-white rounded">

                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <!-- <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                        </div> -->
                                        <div class="form-group">
                                            <div class="controls">
                                                <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                                <div style="margin-top: .5rem">
                                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                        <div>
                                                            <input v-model="currentState.value" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                            <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                        </div>
                                                        <span class="font-medium-1">{{ (currentState.value) == 1 ? "Active" : "Inactive" }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentOnlineAppointmentStatus" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_same_day_surcharge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">

                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th style="width: 10%;" scope="col">Name</th>
                                                    <th style="width: auto;" scope="col">Value</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="col"> Rate</th>
                                                    <td>
                                                        <input type="number" class="form-control" placeholder="" v-model="currentState.value.rate">
                                                        <div class="">
                                                            <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                                        </div>

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="col"> status </th>
                                                    <td>
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <div style="margin-top: .5rem">
                                                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                                        <div>
                                                                            <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                                            <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                                        </div>
                                                                        <span class="font-medium-1">{{ (currentState.value.status) == 1 ? "Active" : "Inactive" }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentSameDaySurcharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_timebase_surcharge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <h5>Details</h5>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singleDetail,index) in currentState.value.details" :key="index">
                                            <div class="col-12">
                                                <div class="row form-group">
                                                    <div class="col-2">
                                                        <p class="mb-0 pb-0">Status</p>
                                                    </div>
                                                    <div class="col-10">
                                                        <div>
                                                            <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                                <div>
                                                                    <input v-model="singleDetail.status" type="checkbox" class="custom-control-input" :id="currentState.key+index">
                                                                    <label class="custom-control-label mr-1" :for="currentState.key+index"></label>
                                                                </div>
                                                                <span class="font-medium-1">{{ (singleDetail.status) == true ? "Active" : "Inactive" }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-2">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-10">
                                                        <input type="number" class="form-control" placeholder="" v-model="singleDetail.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-2">
                                                        <p class="mb-0 pb-0">Start Time</p>
                                                    </div>
                                                    <div class="col-10">
                                                        <input type="text" class="form-control" placeholder="" v-model="singleDetail.start_time">
                                                        <p class="text-muted">Input format : 00:00:00 (H:M:S) </p>

                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-2">
                                                        <p class="mb-0 pb-0">End Time</p>
                                                    </div>
                                                    <div class="col-10">
                                                        <input type="text" class="form-control" placeholder="" v-model="singleDetail.end_time">
                                                        <p class="text-muted">Input format : 00:00:00 (H:M:S) </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentTimeBaseSurcharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_distance_surcharge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <h5>Details</h5>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singleDetail,index) in currentState.value.details" :key="index">
                                            <div class="col-12">

                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="number" class="form-control" placeholder="" v-model="singleDetail.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Distance upto</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="number" class="form-control" placeholder="" v-model="singleDetail.distance_upto">

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentDistanceSurcharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_holiday_surcharge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">

                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th style="width: 10%;" scope="col">Name</th>
                                                    <th style="width: auto;" scope="col">Value</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="col"> Rate</th>
                                                    <td>
                                                        <input type="text" class="form-control" placeholder="" v-model="currentState.value.rate">
                                                        <div class="">
                                                            <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                                        </div>

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="col"> status </th>
                                                    <td>
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <div style="margin-top: .5rem">
                                                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                                        <div>
                                                                            <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                                            <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                                        </div>
                                                                        <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentHolyDaySurcharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_weekend_surcharge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row form-group">
                                        <div class="col-12">
                                            <label>Rate</label>
                                            <div>
                                                <input type="text" class="form-control" placeholder="" v-model="currentState.value.rate">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row form-group">
                                        <div class="col-12 d-sm-flex">
                                            <p class="mb-0 pb-0">Apply with holiday</p>
                                            <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center ml-sm-2">
                                                <div>
                                                    <input v-model="currentState.value.apply_with_holiday" type="checkbox" class="custom-control-input" :id="currentState.key+index">
                                                    <label class="custom-control-label mr-1" :for="currentState.key+index"></label>
                                                </div>
                                                <span class="font-medium-1">{{ (currentState.value.apply_with_holiday) == true ? "Active" : "Inactive" }}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <h5>Days</h5>
                                    <div class="form-group">
                                        <div class="row" v-for="(week,index) in weekNameList" :key="index">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class="">
                                                        <div class="custom-control custom-checkbox d-flex align-items-baseline">
                                                            <input class="custom-control-input" type="checkbox" v-model="currentState.value.days" :value="week" :id="week+index">
                                                            <label class="custom-control-label" :for="week+index"> {{week}} </label>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentWeekendSurcharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_service_duration'" class="card-body bg-white rounded">

                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                            <p class="text-muted">Input format : 00:00 (H:M) </p>
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentServiceDuration" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_on_site_maximum_distance'" class="card-body bg-white rounded">

                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">

                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentOnSiteMaximumDistance" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_refund_policy'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="d-flex justify-content-between my-1">
                                        <h5>Policies</h5>
                                        <button @click="addNewPolicy" class="btn btn-sm btn-success ">Add </button>
                                    </div>

                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singlePolicy,index) in currentState.value.penalty_policies" :key="index">
                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Hour within</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.hour_within">

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removePolicy(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentRefundPolicy" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_credited_policy'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between my-1">
                                        <h5>Policies</h5>
                                        <button @click="addNewPolicy" class="btn btn-sm btn-success ">Add </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singlePolicy,index) in currentState.value.penalty_policies" :key="index">
                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Hour within</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.hour_within">

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removePolicy(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentCreditedPolicy" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_reschedule_by_customer_policy'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between my-1">
                                        <h5>Policies</h5>
                                        <button @click="addNewPolicy" class="btn btn-sm btn-success ">Add </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singlePolicy,index) in currentState.value.penalty_policies" :key="index">
                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Hour within</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.hour_within">

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removePolicy(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentReschedulePolicy" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div v-if="currentState.key === 'appointment_cancellation_by_customer_policy'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between my-1">
                                        <h5 class="text-capitalize">Cancellation refund penalty policies</h5>
                                        <button @click="addNewCancellationRefundPenaltyPolicy" class="btn btn-sm btn-success ">Add </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singlePolicy,index) in currentState.value.cancellation_refund_penalty_policies" :key="index">

                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Hour within</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.hour_within">

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removeCancellationRefundPenaltyPolicy(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between my-1">
                                        <h5 class="text-capitalize">Cancellation credited penalty policies</h5>
                                        <button @click="addNewCancellationCreditedPenaltyPolicy" class="btn btn-sm btn-success ">Add </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singlePolicy,index) in currentState.value.cancellation_credited_penalty_policies" :key="index">

                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Hour within</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="singlePolicy.hour_within">

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removeCancellationCreditedPenaltyPolicy(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentCancellationPolicyByCustomer" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_online_appointment_discount'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">

                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th style="width: 10%;" scope="col">Name</th>
                                                    <th style="width: auto;" scope="col">Value</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <th scope="col"> Max amount</th>
                                                    <td>
                                                        <input type="text" class="form-control" placeholder="" v-model="currentState.value.max_discount_amount">

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="col"> Discount Rate</th>
                                                    <td>
                                                        <input type="text" class="form-control" placeholder="" v-model="currentState.value.discount_rate">

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th scope="col"> status </th>
                                                    <td>
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <div style="margin-top: .5rem">
                                                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                                        <div>
                                                                            <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                                            <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                                        </div>
                                                                        <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>

                                </div>

                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentOnlineAppointmentDiscount" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div v-if="currentState.key === 'appointment_parking_discount'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("appointment", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group">
                                        <div class="row mb-2 p-2" >
                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Discount Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="currentState.value.discount_rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Max discount amount</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="text" class="form-control" placeholder="" v-model="currentState.value.max_discount_amount">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6>Applicable Parking Type</h6>
                                    <div class="form-group">
                                        <div class="row" v-for="(parkingType,index) in settingDefaultParkingType.value" :key="index">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class="">
                                                        <div class="custom-control custom-checkbox d-flex align-items-baseline">
                                                            <input class="custom-control-input" type="checkbox" v-model="currentState.value.applicable_parking_type" :value="parkingType.value" :id="parkingType+index">
                                                            <label class="custom-control-label" :for="parkingType+index"> {{parkingType.name}} </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateAppointmentParkingDiscount" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    parseInt
} from 'lodash';

export default {
    name: "AppointmentSettings",
    components: {
        AppLayout,
    },
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            getSettingsParams: {
                type: ["appointment","default"],
                key: [
                    "appointment_reference_prefix",
                    "appointment_reference_suffix",
                    "appointment_online_appointment_status",
                    "appointment_same_day_surcharge",
                    "appointment_timebase_surcharge",
                    "appointment_distance_surcharge",
                    "appointment_holiday_surcharge",
                    "appointment_weekend_surcharge",
                    "appointment_service_duration",
                    "appointment_on_site_maximum_distance",
                    "appointment_refund_policy",
                    "appointment_credited_policy",
                    "appointment_reschedule_by_customer_policy",
                    "appointment_cancellation_by_customer_policy",
                    "appointment_online_appointment_discount",
                    "appointment_parking_discount",
                    "default_parking_type"

                ],
            },
            currentState: {},
            frontEndErrors: [],

        }

    },

    computed: {
        ...mapGetters({
            settingAppointmentReferencePrefix: "appSettings/settingAppointmentReferencePrefix",
            settingAppointmentReferenceSuffix: "appSettings/settingAppointmentReferenceSuffix",
            settingAppointmentOnlineBookingStatus: "appSettings/settingAppointmentOnlineBookingStatus",
            settingAppointmentSameDaySurcharge: "appSettings/settingAppointmentSameDaySurcharge",
            settingAppointmentTimebaseSurcharge: "appSettings/settingAppointmentTimebaseSurcharge",
            settingAppointmentDistanceSurCharge: "appSettings/settingAppointmentDistanceSurCharge",
            settingAppointmentHolidaySurcharge: "appSettings/settingAppointmentHolidaySurcharge",
            settingAppointmentWeekendSurcharge: "appSettings/settingAppointmentWeekendSurcharge",
            settingAppointmentServiceDuration: "appSettings/settingAppointmentServiceDuration",
            settingAppointmentOnSiteMaximumDistance: "appSettings/settingAppointmentOnSiteMaximumDistance",
            settingAppointmentRefundPolicy: "appSettings/settingAppointmentRefundPolicy",
            settingAppointmentCreditedPolicy: "appSettings/settingAppointmentCreditedPolicy",
            settingAppointmentReschedulePolicy: "appSettings/settingAppointmentReschedulePolicy",
            settingAppointmentCancellationPolicyByCustomer: "appSettings/settingAppointmentCancellationPolicyByCustomer",
            settingAppointmentOnlineAppointmentDiscount: "appSettings/settingAppointmentOnlineAppointmentDiscount",
            settingAppointmentParkingDiscount: "appSettings/settingAppointmentParkingDiscount",
            settingDefaultParkingType: "appSettings/settingDefaultParkingType",

        }),
        appointmentSettings() {
            return [this.settingAppointmentReferencePrefix, this.settingAppointmentReferenceSuffix, this.settingAppointmentOnlineBookingStatus, this.settingAppointmentSameDaySurcharge, this.settingAppointmentTimebaseSurcharge, this.settingAppointmentDistanceSurCharge, this.settingAppointmentHolidaySurcharge, this.settingAppointmentWeekendSurcharge, this.settingAppointmentServiceDuration, this.settingAppointmentCancellationPolicyByCustomer, this.settingAppointmentOnSiteMaximumDistance, this.settingAppointmentRefundPolicy, this.settingAppointmentCreditedPolicy, this.settingAppointmentReschedulePolicy, this.settingAppointmentOnlineAppointmentDiscount, this.settingAppointmentParkingDiscount

            ]
        },

        weekNameList() {
            return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        }

    },
    watch: {
        currentState(exactState) {
            this.frontEndErrors = [];
            if (exactState.key == 'appointment_online_appointment_status') {
                this.currentState.value = Number(this.currentState.value) === 1 ? true : false;
            }
            if (exactState.key == 'appointment_same_day_surcharge') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == ('appointment_timebase_surcharge')) {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }
                if (this.currentState.value.details.length > 0) {

                    this.currentState.value.details.forEach((currentValue) => {
                        currentValue.status = Number(currentValue.status) === 1 ? true : false;

                    })

                }

            }
            if (exactState.key == ('appointment_holiday_surcharge')) {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_distance_surcharge') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }
                if (this.currentState.value.details.length > 0) {

                    this.currentState.value.details.forEach((currentValue) => {
                        currentValue.status = Number(currentValue.status) === 1 ? true : false;

                    })

                }

            }
            if (exactState.key == 'appointment_weekend_surcharge') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }
                if (this.currentState.value.apply_with_holiday) {
                    this.currentState.value.apply_with_holiday = Number(this.currentState.value.apply_with_holiday) == 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_online_appointment_discount') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_refund_policy') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_credited_policy') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_reschedule_by_customer_policy') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_cancellation_by_customer_policy') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }
            if (exactState.key == 'appointment_parking_discount') {
                if (this.currentState.value.status) {
                    this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
                }

            }

        }

    },
    methods: {
        ...mapActions({

            getSettings: "appSettings/getSettings",
            putSetting: "appSettings/putSetting",

        }),
        updateAppointmentTimeBaseSurchargeRate(event, index) {
            this.currentState.value.details[index].rate = event.target.value;

        },
        async getSettingList(settingParams) {
            this.loader(true);
            const response = await this.getSettings(settingParams);
            this.loader(false);
            if (response.status == 200) {
                this.currentState = {
                    ...this.settingAppointmentReferencePrefix
                };

            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },
        async updateAppointmentReferencePrefix() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Reference prefix value is required");
            } else {
                this.updateSettingByApiCall(this.currentState);
            }

        },
        async updateAppointmentServiceDuration() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value);
            if (!nullChecker) {
                this.frontEndErrors.push("Service duration value is required");
            } else {
                this.updateSettingByApiCall(this.currentState);
            }

        },
        async updateAppointmentOnSiteMaximumDistance() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value);
            if (!nullChecker) {
                this.frontEndErrors.push("On site maximum distance value is required");
            } else {
                this.updateSettingByApiCall(this.currentState);
            }

        },

        async updateAppointmentReferenceSuffix() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Reference suffix value is required");
            } else {
                this.updateSettingByApiCall(this.currentState);
            }
        },
        async updateAppointmentOnlineAppointmentStatus() {
            this.frontEndErrors = [];

            await this.updateSettingByApiCall({
                ...this.currentState,
                value: this.currentState.value == true ? '1' : "0",
            });

        },

        async updateAppointmentSameDaySurcharge() {
            this.frontEndErrors = [];

            let nullChecker = !!(this.currentState.value.rate + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Same day surcharge rate value is required");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        rate: parseInt(this.currentState.value.rate)
                    }
                });
            }
        },
        async updateAppointmentHolyDaySurcharge() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value.rate);
            if (!nullChecker) {
                this.frontEndErrors.push("Holyday rate value is required");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        rate: this.currentState.value.rate
                    }
                });
            }
        },
        async updateAppointmentOnlineAppointmentDiscount() {
            this.frontEndErrors = [];
            let nullChecker = (Boolean(this.currentState.value.discount_rate + '') == false || Boolean(this.currentState.value.max_discount_amount + '') == false);
            if (nullChecker == true) {
                this.frontEndErrors.push("Discount rate and max discount amount value can't be empty");
                this.showToastMessage({
                    type: 'error',
                    message: `Discount rate and max discount amount value can't be empty`
                });
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        ...this.currentState.value,
                        status: this.currentState.value.status == true ? 1 : 0,
                        max_discount_amount: this.currentState.value.max_discount_amount,
                        discount_rate: this.currentState.value.discount_rate

                    }
                });
            }
        },
        async updateAppointmentTimeBaseSurcharge() {
            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.details.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.start_time) && Boolean(item.end_time));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Time Base sur charge rate, start time, end time value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Time Base sur charge rate, start time, end time value can't be empty `
                });
            } else {

                let details = [];
                this.currentState.value.details.forEach((currentItem) => {
                    let obj = {
                        status: currentItem.status == true ? 1 : 0,
                        rate: parseInt(currentItem.rate),
                        start_time: currentItem.start_time,
                        end_time: currentItem.end_time
                    }
                    details.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        details: details
                    }
                });
            }
        },
        async updateAppointmentDistanceSurcharge() {
            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.details.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.distance_upto + ''));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Distance sur charge rate, Distance upto value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Distance sur charge rate, Distance upto value can't be empty   `
                });
            } else {
                let details = [];
                this.currentState.value.details.forEach((currentItem) => {
                    let obj = {
                        distance_upto: parseInt(currentItem.distance_upto),
                        rate: parseInt(currentItem.rate),

                    }
                    details.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        details: details
                    }
                });
            }
        },
        async updateAppointmentWeekendSurcharge() {
            this.frontEndErrors = [];

            let isDayNull = this.currentState.value.days.length == 0 ? false : true;

            let isRateNull = (Boolean(this.currentState.value.rate));


            if ((isDayNull == false) || (isRateNull == false)) {
                this.frontEndErrors.push("Weekend sur charge rate & days value can't be empty  ");
            } else {

                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        apply_with_holiday: this.currentState.value.apply_with_holiday == true ? 1 : 0,
                        rate: this.currentState.value.rate,
                        days: this.currentState.value.days
                    }
                });
            }
        },
        async updateAppointmentRefundPolicy() {
            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Refund policy rate, Hour within value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Refund policy rate, Hour within value can't be empty   `
                });
            } else {
                let policies = [];
                this.currentState.value.penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    policies.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        penalty_policies: policies
                    }
                });
            }
        },

        async updateAppointmentCreditedPolicy() {
            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Credited policy rate, Hour within value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Credited policy rate, Hour within value can't be empty   `
                });

            } else {
                let policies = [];
                this.currentState.value.penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    policies.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        penalty_policies: policies
                    }
                });
            }
        },
        async updateAppointmentCancellationPolicyByCustomer() {
            this.frontEndErrors = [];

            let nullCheckerForCancellationRefundPenaltyPolicy = this.currentState.value.cancellation_refund_penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false);

            let nullCheckerForCancellationCreditedPenaltyPolicy = this.currentState.value.cancellation_credited_penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false);

            if (nullCheckerForCancellationRefundPenaltyPolicy === true || nullCheckerForCancellationCreditedPenaltyPolicy === true) {
                if (nullCheckerForCancellationRefundPenaltyPolicy === true) {
                    this.frontEndErrors.push("Cancellation refund penalty policy rate, Hour within value can't be empty  ");
                    this.showToastMessage({
                        type: 'error',
                        message: `Cancellation refund penalty policy rate, Hour within value can't be empty   `
                    });
                }
                if (nullCheckerForCancellationCreditedPenaltyPolicy === true) {
                    this.frontEndErrors.push("Cancellation credited penalty policy rate, Hour within value can't be empty  ");
                    this.showToastMessage({
                        type: 'error',
                        message: `Cancellation credited penalty policy rate, Hour within value can't be empty   `
                    });
                }

            } else {
                let cancellationRefundPenaltyPolicies = [];
                this.currentState.value.cancellation_refund_penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    cancellationRefundPenaltyPolicies.push(obj);
                });
                let cancellationCreditedPenaltyPolicies = [];
                this.currentState.value.cancellation_credited_penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    cancellationCreditedPenaltyPolicies.push(obj);
                });
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        cancellation_refund_penalty_policies: cancellationRefundPenaltyPolicies,
                        cancellation_credited_penalty_policies: cancellationCreditedPenaltyPolicies
                    }
                });
            }
        },
        removePolicy(removeItemIndex) {
            if (this.currentState.value.penalty_policies.length !== 1) {
                this.currentState.value.penalty_policies = this.currentState.value.penalty_policies.filter((_, index) => index !== removeItemIndex);
                return;
            }
            this.showToastMessage({
                type: 'error',
                message: `Can't remove this item`
            });

        },
        removeCancellationRefundPenaltyPolicy(removeItemIndex) {
            if (this.currentState.value.cancellation_refund_penalty_policies.length !== 1) {
                this.currentState.value.cancellation_refund_penalty_policies = this.currentState.value.cancellation_refund_penalty_policies.filter((_, index) => index !== removeItemIndex);
                return;
            }
            this.showToastMessage({
                type: 'error',
                message: `Can't remove this item`
            });

        },
        removeCancellationCreditedPenaltyPolicy(removeItemIndex) {
            if (this.currentState.value.cancellation_credited_penalty_policies.length !== 1) {
                this.currentState.value.cancellation_credited_penalty_policies = this.currentState.value.cancellation_credited_penalty_policies.filter((_, index) => index !== removeItemIndex);
                return;
            }
            this.showToastMessage({
                type: 'error',
                message: `Can't remove this item`
            });

        },
        addNewPolicy() {
            this.currentState.value.penalty_policies.push({
                hour_within: '',
                rate: ''
            });

        },
        addNewCancellationRefundPenaltyPolicy() {
            this.currentState.value.cancellation_refund_penalty_policies.push({
                hour_within: '',
                rate: ''
            });
        },
        addNewCancellationCreditedPenaltyPolicy() {
            this.currentState.value.cancellation_credited_penalty_policies.push({
                hour_within: '',
                rate: ''
            });
        },
        async updateAppointmentReschedulePolicy() {
            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Reschedule policy rate, Hour within value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Reschedule policy rate, Hour within value can't be empty   `
                });

            } else {
                let policies = [];
                this.currentState.value.penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    policies.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        penalty_policies: policies
                    }
                });
            }
        },
          async updateAppointmentParkingDiscount() {

             this.frontEndErrors = [];

            let isDiscountRateNull = (Boolean(this.currentState.value.discount_rate+''));
            let isMaxDiscountAmountNull = (Boolean(this.currentState.value.max_discount_amount+''));


            if ((isDiscountRateNull == false) || (isMaxDiscountAmountNull == false)) {
                this.showToastMessage({
                    type: 'error',
                    message: `Discount rate and max discount amount values can't be empty`
                });
                this.frontEndErrors.push("Discount rate and max discount amount values can't be empty");
            } else {

                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        discount_rate: this.currentState.value.discount_rate,
                        max_discount_amount: this.currentState.value.max_discount_amount,
                        applicable_parking_type: this.currentState.value.applicable_parking_type
                    }
                });
            }


            this.frontEndErrors = [];

            let nullChecker = this.currentState.value.penalty_policies.map((item) => {

                return (Boolean(item.rate + '') && Boolean(item.hour_within + ''));

            }).some((item) => item == false)

            if (nullChecker === true) {
                this.frontEndErrors.push("Refund policy rate, Hour within value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Refund policy rate, Hour within value can't be empty   `
                });
            } else {
                let policies = [];
                this.currentState.value.penalty_policies.forEach((currentItem) => {
                    let obj = {
                        hour_within: parseInt(currentItem.hour_within),
                        rate: parseInt(currentItem.rate),

                    }
                    policies.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        penalty_policies: policies
                    }
                });
            }
        },

        async updateSettingByApiCall(dataObjParam) {

            this.loader(true);
            const data = {
                type: dataObjParam.type,
                key: dataObjParam.key,
                value: dataObjParam.value,

            }
            const dataObj = {
                id: this.currentState.id,
                data: data
            }
            const response = await this.putSetting(dataObj);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200) {

                this.showToastMessage({
                    message: 'Settings updated',
                    type: 'success'
                });

            }

        },

    },
    async mounted() {
        this.loader(true);
        await this.getSettingList(this.getSettingsParams);

        // this.appointmentSettings = [this.settingAppointmentReferencePrefix,]
        // this.copySettingResponse = [this.settingDefaultPagination, this.settingDefaultWebsiteUrl, this.settingDefaultStateTimezone, this.settingDefaultParkingType,this.settingDefaultContactNumber,this.settingDefaultGstRate];

    },

}
</script>

<style scoped>
h6 {
    color: #475F7B !important;
}

.icon-custom-font-size {
    font-size: 20px;
}

ul {
    text-decoration: none;
    list-style: none;
}

.div-exact-active {
    background-color: var(--primary-color);
    color: white !important;

}

.div-exact-active:hover {
    color: white !important;

}

.state-side-bar-nav-item:hover {
    color: var(--primary-color);
}

.state-side-bar-nav-item {
    cursor: pointer;
    color: #475f7b;
}
</style>
